export const blankCharacter = {
  name: "",
  level: 0,
  race: "",
  age: "",
  size: "medium",
  alignment: "",
  xp: 0,
  background: {
    title: "",
    characteristic: "",
  },
  class: "",
  subclass: "",
  multiclasses: [
    {
      class_id: 0,
      class_level: 1,
      subclass: "",
      hit_dice: "",
    },
  ],
  hit_dice: {
    dice: "",
    max: 0,
    current: 0,
    mult1_dice: "",
    mult1_max: 0,
    mult1_current: 0,
    mult2_dice: "",
    mult2_max: 0,
    mult2_current: 0,
    mult3_dice: "",
    mult3_max: 0,
    mult3_current: 0,
    mult4_dice: "",
    mult4_max: 0,
    mult4_current: 0,
    mult5_dice: "",
    mult5_max: 0,
    mult5_current: 0,
  },
  hp: {
    max: 0,
    current: 0,
    temp: 0,
    temp_max: "0",
    temp_ac: 0,
    temp_speed: 0,
    other_speeds: "",
    status: "",
  },
  defences: {
    resistances: "",
    immunities: "",
    vulnerabilities: "",
  },
  death_saves: {
    pass: 0,
    fail: 0,
  },
  exhaustion: 0,
  ac: 10,
  speed: 0,
  initiative: 0,
  proficiency_bonus: 2,
  inspiration: false,
  stats: {
    str: 8,
    temp_str: 0,
    dex: 8,
    temp_dex: 0,
    con: 8,
    temp_con: 0,
    int: 8,
    temp_int: 0,
    wis: 8,
    temp_wis: 0,
    cha: 8,
    temp_cha: 0,
  },
  saves: {
    str: false,
    str_bonus: 0,
    dex: false,
    dex_bonus: 0,
    con: false,
    con_bonus: 0,
    int: false,
    int_bonus: 0,
    wis: false,
    wis_bonus: 0,
    cha: false,
    cha_bonus: 0,
  },
  skills: {
    Athletics: "",
    Athletics_bonus: 0,
    Acrobatics: "",
    Acrobatics_bonus: 0,
    SleightOfHand: "",
    SleightOfHand_bonus: 0,
    Stealth: "",
    Stealth_bonus: 0,
    Arcana: "",
    Arcana_bonus: 0,
    History: "",
    History_bonus: 0,
    Investigation: "",
    Investigation_bonus: 0,
    Nature: "",
    Nature_bonus: 0,
    Religion: "",
    Religion_bonus: 0,
    AnimalHandling: "",
    AnimalHandling_bonus: 0,
    Insight: "",
    Insight_bonus: 0,
    Medicine: "",
    Medicine_bonus: 0,
    Perception: "",
    Perception_bonus: 0,
    Survival: "",
    Survival_bonus: 0,
    Deception: "",
    Deception_bonus: 0,
    Intimidation: "",
    Intimidation_bonus: 0,
    Performance: "",
    Performance_bonus: 0,
    Persuasion: "",
    Persuasion_bonus: 0,
  },
  passives: {
    senses: "",
    perception: 0,
    perception_bonus: 0,
    investigation: 0,
    investigation_bonus: 0,
    insight: 0,
    insight_bonus: 0,
  },
  features: [
    // {
    //   feature_id: 0,
    //   level_acquired: 0,
    //   feature_name: "",
    //   source: "",
    //   description: "",
    //   max_uses: 0,
    //   current_uses: 0,
    //   recharge: "",
    // },
  ],
  attacks: [
    {
      attack_id: 0,
      attack_name: "Unarmed",
      attack_bonus: 0,
      damage_bonus: 0,
      damage_dice: "",
      damage_dice_num: 1,
      damage_type: "Bludgeoning",
      range: "",
      tags: "",
      ammo: 0,
    },
    // {
    //   attack_id: 0,
    //   attack_name: "",
    //   attack_bonus: 0,
    //   damage_bonus: 0,
    //   damage_dice: "",
    //   damage_dice_num: 0,
    //   damage_type: "",
    //   range: "",
    //   tags: "",
    //   ammo: 0,
    // },
  ],
  magic: {
    magic_user: "",
    save_dc: 0,
    save_dc_bonus: 0,
    spell_attack_mod: 0,
    spell_attack_bonus: 0,
    ability: "",
    cantrips_known: 0,
    spells_known: 0,
    concentrating: false,
  },
  // spellslots: [
  //   { first: 0, first_remaining: 0 },
  //   { second: 0, second_remaining: 0 },
  //   { third: 0, third_remaining: 0 },
  //   { fourth: 0, fourth_remaining: 0 },
  //   { fifth: 0, fifth_remaining: 0 },
  //   { sixth: 0, sixth_remaining: 0 },
  //   { seventh: 0, seventh_remaining: 0 },
  //   { eighth: 0, eighth_remaining: 0 },
  //   { ninth: 0, ninth_remaining: 0 },
  // ],
  spellslots: {
    first: 0,
    first_remaining: 0,
    second: 0,
    second_remaining: 0,
    third: 0,
    third_remaining: 0,
    fourth: 0,
    fourth_remaining: 0,
    fifth: 0,
    fifth_remaining: 0,
    sixth: 0,
    sixth_remaining: 0,
    seventh: 0,
    seventh_remaining: 0,
    eighth: 0,
    eighth_remaining: 0,
    ninth: 0,
    ninth_remaining: 0,
  },
  inventory: [
    // {
    //   item_id: 0,
    //   item_name: "",
    //   quantity: 0,
    //   value_each: 0,
    //   value_currency: "",
    //   value_total: 0,
    // },
  ],
  currency: {
    copper: 0,
    silver: 0,
    electrum: 0,
    gold: 0,
    platinum: 0,
  },
  proficiencies: {
    languages: "",
    weapons: "",
    armour: "",
    other: "",
  },
  equipment: [
    // {
    //   equipment_id: 0,
    //   equipment_name: "",
    //   equipment_type: "",
    //   attuned: false,
    //   desc: "",
    //   value: 0,
    //   value_currency: "",
    //   max_uses: 0,
    //   recharge: "",
    // },
  ],
  personality: {
    trait1: "",
    trait2: "",
    ideal: "",
    bond: "",
    flaw: "",
  },
  notes: [
    // {
    //   note_id: 0,
    //   title: "",
    //   note_body: "",   
    // },
  ],
};
