import React from "react";
import "../App.css";
import Refined from "../components/CharacterCreate/refinedSheet";

const LoadRefined = () => {
  return (
    <div>
      <Refined />
    </div>
  );
};

export default LoadRefined;
