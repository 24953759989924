import React from "react";
import "../App.css";
import Success from "../components/CharacterCreate/success";

const LoadCharacter = () => {
  return (
    <div>
      <Success />
    </div>
  );
};

export default LoadCharacter;
